import { alpha, Box, CircularProgress, Modal } from '@mui/material'
import React from 'react'

const FullPageLoading: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Modal open aria-label={'Carregando'}>
      <Box
        sx={{
          background: alpha('#fefefecc', 0.1),
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <CircularProgress size="8rem" />
      </Box>
    </Modal>
  )
}

export default FullPageLoading
